import 'Modernizr';
import 'alpinejs';

import baguetteBox from 'baguettebox.js';

baguetteBox.run('.baguette-box');

const ScrollTrigger = require("scrolltrigger-classes");
var trigger = new ScrollTrigger({
    toggle: {
        visible: 'is-visible',
        hidden: 'is-not-visible'
    },
    offset: {
        x: 0,
        y: 0
    },
    addHeight: false,
    once: true
});